<template>
  <v-card class="pa-4 admin-report-card">
    <v-row class="d-flex report-wrap">
      <v-col
        md="3"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <p class="text-h6">
          Offline Conversion Report
        </p>
      </v-col>
      <v-col
        md="6"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <date-picker v-model="dateSelected" />
      </v-col>
      <v-col
        md="3"
        sm="12"
        cols="12">
        <v-spacer />
        <v-btn
          color="success"
          class="mt-2"
          block
          @click="exportCsv()">
          EXPORT CSV
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          hide-default-footer
          :loading="fetching"
          dense>
          <template #[`item.createdAt`]="{ item }">
            {{ $dayjs(item.createdAt).format('YYYY-MM-DD HH:mm') }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import AdminAndCashierReportProvider from '@/resources/AdminAndCashierReportProvider'
import { ExportToCsv } from 'export-to-csv'
import DatePicker from '@/components/DateRangePicker.vue'

const AdminAndCashierReportService = new AdminAndCashierReportProvider()

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      fetching: false,
      headers: [
        { text: 'Datetime', value: 'createdAt' },
        { text: 'Bill ID', value: 'billId' },
        { text: 'Cashier', value: 'cashier' },
        { text: 'Tel No.', value: 'tel' },
        { text: 'Price', value: 'netPrice' },
        { text: 'Net Price', value: 'netBillPrice' },
        { text: 'Branch', value: 'warehouse' }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()],
      items: []
    }
  },
  watch: {
    dateSelected: {
      handler () {
        this.fetchReport()
      },
      deep: true
    }
  },
  mounted () {
    this.fetchReport()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async fetchReport () {
      try {
        this.fetching = true
        const { data } = await AdminAndCashierReportService.getOfflineConversionsReport({
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.items = data
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `[error on fetch report]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    },
    exportCsv () {
      const preExport = this.items.map((item) => ({
        dateTime: this.$dayjs(item.createdAt).format('YYYY-MM-DDTHH:mm'),
        billId: item.billId,
        cashier: item.cashier,
        tel: item.tel,
        price: item.netPrice,
        netPrice: item.netBillPrice,
        warehouse: item.warehouse
      }))

      const options = {
        filename: `OFFLINE-CONVERSION(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(preExport)
    }
  }
}
</script>
